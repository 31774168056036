/* Theme Name:iDea - Clean & Powerful Bootstrap Theme
 * Author:HtmlCoder
 * Author URI:http://www.htmlcoder.me
 * Author e-mail:htmlcoder.me@gmail.com
 * Version: 1.3
 * Created:October 2014
 * License URI:http://support.wrapbootstrap.com/
 * File Description: Initializations of plugins 
 *
 * THIS FILE IS MODIFIED FROM THE ORIGINAL BY ALKACON
 * All options / init routines not required by our template have been removed.
 */

(function($){
	$(document).ready(function(){

		$(window).load(function() {
			$("body").removeClass("no-trans");
		});

		// Fixed header
		//-----------------------------------------------
		var	headerTopHeight = $(".header-top").outerHeight(),
		headerHeight = $("header.header.fixed").outerHeight();
		$(window).scroll(function() {
			if (($(".header.fixed").length > 0)) { 
				if(($(this).scrollTop() > headerTopHeight+headerHeight) && ($(window).width() > 767)) {
					$("body").addClass("fixed-header-on");
					$(".header.fixed").addClass('animated object-visible fadeInDown');
					if (!($(".header.transparent").length>0)) {
						if ($(".banner:not(.header-top)").length>0) {
							$(".banner").css("marginTop", (headerHeight)+"px");
						} else if ($(".page-intro").length>0) {
							$(".page-intro").css("marginTop", (headerHeight)+"px");
						} else if ($(".page-top").length>0) {
							$(".page-top").css("marginTop", (headerHeight)+"px");
						} else {
							$("section.main-container").css("marginTop", (headerHeight)+"px");
						}
					}
				} else {
					$("body").removeClass("fixed-header-on");
					$("section.main-container").css("marginTop", (0)+"px");
					$(".banner").css("marginTop", (0)+"px");
					$(".page-intro").css("marginTop", (0)+"px");
					$(".page-top").css("marginTop", (0)+"px");
					$(".header.fixed").removeClass('animated object-visible fadeInDown');
				}
			};
		});

		// Affix plugin
		//-----------------------------------------------
		if ($("#affix").length>0) {
			$(window).load(function() {

				var affixBottom = $(".footer").outerHeight(true) + $(".subfooter").outerHeight(true) + $(".blogpost footer").outerHeight(true),
				affixTop = $("#affix").offset().top;
				
				if ($(".comments").length>0) {
					affixBottom = affixBottom + $(".comments").outerHeight(true);
				}

				if ($(".comments-form").length>0) {
					affixBottom = affixBottom + $(".comments-form").outerHeight(true);
				}

				if ($(".footer-top").length>0) {
					affixBottom = affixBottom + $(".footer-top").outerHeight(true);
				}

				if ($(".header.fixed").length>0) {
					$("#affix").affix({
				        offset: {
				          top: affixTop-150,
				          bottom: affixBottom+100
				        }
				    });
				} else {
					$("#affix").affix({
				        offset: {
				          top: affixTop-35,
				          bottom: affixBottom+100
				        }
				    });
				}

			});
		}
		if ($(".affix-menu").length>0) {
			setTimeout(function () {
				var $sideBar = $('.sidebar')

				$sideBar.affix({
					offset: {
						top: function () {
							var offsetTop      = $sideBar.offset().top
							return (this.top = offsetTop - 65)
						},
						bottom: function () {
							var affixBottom = $(".footer").outerHeight(true) + $(".subfooter").outerHeight(true)
							if ($(".footer-top").length>0) {
								affixBottom = affixBottom + $(".footer-top").outerHeight(true)
							}						
							return (this.bottom = affixBottom+50)
						}
					}
				})
			}, 100)
		}
		
		// Scroll totop
		//-----------------------------------------------
		$(window).scroll(function() {
			if($(this).scrollTop() != 0) {
				$(".scrollToTop").fadeIn();	
			} else {
				$(".scrollToTop").fadeOut();
			}
		});
		
		$(".scrollToTop").click(function() {
			$("body,html").animate({scrollTop:0},800);
		});

		// Parallax initialization
		// -----------------------------------------------
		if (!Modernizr.touch && ($(".parallax-img").length > 0)) {
			$(".parallax-img").parallax("50%", 0.7, false, 1200);
		};

		// This will prevent the event from bubbling up and close the dropdown when you type/click on text boxes (Header Top).
		//-----------------------------------------------
		$('.header-top .dropdown-menu input').click(function(e) {
			e.stopPropagation(); 
		});
		
        // Navigation initialization
        // -----------------------------------------------
        $.post( navPage, { mode: "createnavigation", site:  site, subsite: subSite }, function( data ) {
            // insert result HTML
            $("#navbar-collapse-1").html(data);
            // initialize hover/click behaviour

             $('.main-navigation .navbar-nav li.dropdown>a, .main-navigation .navbar-nav ul.dropdown-menu li>a, .main-navigation .navbar-nav ul.menu li>a').each(
                function() {
                    var linkTarget = $(this).attr("href");
                    // check if current URI starts with navigation element link
                    if (currUri.indexOf(linkTarget) === 0) {
                        $(this).parent("li").addClass("active");
                    }
                }
            );

            //Show dropdown on hover only for desktop devices
            //-----------------------------------------------
            var delay=0, setTimeoutConst;
            if ((Modernizr.mq('only all and (min-width: 768px)') && !Modernizr.touch) || $("html.ie8").length>0) {
                $('.main-navigation .navbar-nav>li.dropdown, .main-navigation li.dropdown>ul>li.dropdown').hover(
                function(){
                    var $this = $(this);
                    setTimeoutConst = setTimeout(function(){
                        $this.addClass('open').slideDown();
                        $this.find('.dropdown-toggle').addClass('disabled');
                    }, delay);
                },  function(){ 
                    clearTimeout(setTimeoutConst );
                    $(this).removeClass('open');
                    $(this).find('.dropdown-toggle').removeClass('disabled');
                });
            };

            //Show dropdown on click only for mobile devices
            //-----------------------------------------------
            if (Modernizr.mq('only all and (max-width: 767px)') || Modernizr.touch) {
                $('.main-navigation [data-toggle=dropdown], .header-top [data-toggle=dropdown]').on('click', function(event) {
                // Avoid following the href location when clicking
                event.preventDefault(); 
                // Avoid having the menu to close when clicking
                event.stopPropagation(); 
                // close all the siblings
                $(this).parent().siblings().removeClass('open');
                // close all the submenus of siblings
                $(this).parent().siblings().find('[data-toggle=dropdown]').parent().removeClass('open');
                // opening the one you clicked on
                $(this).parent().toggleClass('open');
                });
            };

            $("#navbar-collapse-1 li.active a[href*='#']").on('click', function(e) {
               // prevent default anchor click behavior
                if ((this.href).substr(0, this.href.indexOf('#')) == (window.location.href).substr(0, window.location.href.indexOf('#'))) {
                    e.preventDefault();
                    // store hash
                    var hash = this.hash;
                    // animate
                    $('html, body').animate({
                            scrollTop: $(hash).offset().top
                        }, 800, function(){
                           // when done, add hash to url (default click behaviour)
                           window.location.hash = hash;
                        }
                    );
                }
            });
        });

	}); // End document ready

})(this.jQuery);
